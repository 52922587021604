import create from 'zustand';

import { DistributorDto } from '../../models/distributor/DistributorDto.types';
import { IsomorphicStore, createIsomorphicStore } from '../index';

import { DistributorState, DistributorStore } from './distributorStore.types';

const initialState: DistributorState = {
    distributor: null
};

const initializeStore: IsomorphicStore<DistributorStore, DistributorState> = (preloadedState) =>
    create<DistributorStore>((set) => ({
        ...initialState,
        ...preloadedState,
        setDistributor(distributor: DistributorDto | null) {
            set({
                distributor
            });
        },
        logout() {
            set({
                distributor: null
            });
        }
    }));

const {
    StoreProvider: DistributorStoreProvider,
    useStore: useDistributorStore,
    useCreateStore: useCreateDistributorStore
} = createIsomorphicStore<typeof initializeStore, DistributorStore, DistributorState>(initializeStore);

export { DistributorStoreProvider, useDistributorStore, useCreateDistributorStore };
