import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useLocalStorage } from 'react-use';

import NoDistributorSelectedModal from '@/components/modal/NoDistributorSelectedModal';
import DistributorPickerDropdown from '@/components/pages/distributor/DistributorPickerDropdown';
import DistributorProfileImageRound from '@cineamo/legacy-frontend-lib/src/components/ui/image/DistributorProfileImageRound';

import { DistributorDto } from '@cineamo/legacy-frontend-lib/src/models/distributor/DistributorDto.types';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { getDistributorById, getDistributors } from '@cineamo/legacy-frontend-lib/src/api/distributor/distributor-api';

import changeDistributorRedirect from '@cineamo/legacy-frontend-lib/src/helper/changeDistributorRedirect';

import useRouterQueryKeyValue from '@cineamo/legacy-frontend-lib/src/hooks/useRouterQueryKeyValue';

import { useDistributorStore } from '@cineamo/legacy-frontend-lib/src/store/distributor/distributorStore';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconDown16 } from '@cineamo/legacy-frontend-lib/src/icons/arrows/ic-down';

export type LastSelectedDistributorUserRelation = {
    user: number;
    lastSelectedDistributorId: number | string;
};

export type DistributorPickerProps = {
    distributorProp?: DistributorDto;
};

export default function DistributorPicker(props: DistributorPickerProps): JSX.Element {
    const { t } = useTranslation('navigation');

    const router = useRouter();

    const { distributor: distributorFromStore, setDistributor } = useDistributorStore();

    const { user, userType } = useUserStore();

    const { distributorProp } = props;

    const [localStorageLastSelectedDistributors, setLocalStorageLastSelectedDistributors] = useLocalStorage<
        LastSelectedDistributorUserRelation[]
    >('lastSelectedDistributor', []);

    const currentDistributor: DistributorDto = distributorProp || distributorFromStore;

    const DistributorDropdownRef = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const pickerRef = useRef(null);

    const [noDistributorSelectedModalIsOpen, setNoDistributorSelectedModalIsOpen] = useState(false);
    const [query, setQuery] = useState<string>();
    const [page, setPage] = useState(1);

    const { queryValue: distributorIdFromQuery } = useRouterQueryKeyValue('distributorId');

    function handleClickOutside(event) {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setOpen(false);
            setQuery(undefined);
            setPage(1);
        }
    }

    function onSearchDistributor(value: string) {
        setPage(1);
        setQuery(value);
    }

    function selectDistributorHandler(distributor: DistributorDto) {
        setDistributor(distributor);
        setOpen(false);
        setNoDistributorSelectedModalIsOpen(false);
        const currentUserLastSelectedDistributor = localStorageLastSelectedDistributors.find(
            (selectedCinema) => selectedCinema.user === user.id
        );
        if (currentUserLastSelectedDistributor) {
            currentUserLastSelectedDistributor.lastSelectedDistributorId = distributor.id;
            setLocalStorageLastSelectedDistributors([
                ...localStorageLastSelectedDistributors.filter(
                    (selectedDistributor) => selectedDistributor.user !== user.id
                ),
                currentUserLastSelectedDistributor
            ]);
        } else {
            const localStorageLastSelectedCinemasNew: LastSelectedDistributorUserRelation[] =
                localStorageLastSelectedDistributors;
            localStorageLastSelectedCinemasNew.push({
                user: user.id,
                lastSelectedDistributorId: distributor.id
            });
            setLocalStorageLastSelectedDistributors(localStorageLastSelectedCinemasNew);
        }
    }

    function onSignOutFromDistributorClick() {
        setDistributor(null);
        setNoDistributorSelectedModalIsOpen(true);
        setLocalStorageLastSelectedDistributors([
            ...localStorageLastSelectedDistributors.filter((selectedCinema) => selectedCinema.user !== user.id)
        ]);
    }

    useEffect(() => {
        if (document && typeof window !== 'undefined') {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            if (document && typeof window !== 'undefined') {
                document.removeEventListener('mousedown', handleClickOutside);
            }
        };
    }, []);

    useEffect(() => {
        const lastSelectedDistributor = localStorageLastSelectedDistributors?.find(
            (selectedCinema) => selectedCinema.user === user.id
        );
        if (lastSelectedDistributor) {
            getDistributorById(
                (distributorIdFromQuery !== '0' ? distributorIdFromQuery : null) ||
                    lastSelectedDistributor.lastSelectedDistributorId
            ).then((distributor) => {
                setDistributor(distributor);
                changeDistributorRedirect(router, distributor);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        data: distributorsEmployedAtResponse,
        isLoading: isLoadingDistributorsEmployedAt,
        isFetching: isFetchingDistributorsEmployedAt
    } = useQuery(
        ['distributors-employed-at', page, query],
        async () => {
            if (userType.includes(Role.ROLE_ADMIN)) {
                return getDistributors({ page, query });
            }
            return getDistributors({
                employedUserId: user.id,
                page,
                query
            });
        },
        {
            keepPreviousData: true,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        const hasLastSelectedCinema = !!localStorageLastSelectedDistributors?.find(
            (selectedDistributor) => selectedDistributor.user === user.id
        );
        if (!hasLastSelectedCinema && distributorsEmployedAtResponse?._embedded?.distributors?.length === 1) {
            selectDistributorHandler(distributorsEmployedAtResponse._embedded.distributors[0]);
        } else if (!hasLastSelectedCinema) {
            setNoDistributorSelectedModalIsOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distributorsEmployedAtResponse]);

    const queryClient = useQueryClient();

    useEffect(() => {
        if (distributorsEmployedAtResponse && distributorsEmployedAtResponse._page_count > 1) {
            queryClient
                .prefetchQuery(['distributors-employed-at', page + 1], () => {
                    if (userType.includes(Role.ROLE_ADMIN)) {
                        return getDistributors({ page: page + 1, query });
                    }
                    return getDistributors({
                        employedUserId: user.id,
                        page: page + 1,
                        query
                    });
                })
                .then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [distributorsEmployedAtResponse, page, queryClient]);

    if (!(userType.includes(Role.ROLE_DISTRIBUTOR) || userType.includes(Role.ROLE_ADMIN))) {
        return null;
    }

    return (
        <>
            <NoDistributorSelectedModal
                modalIsOpen={noDistributorSelectedModalIsOpen}
                setModalIsOpen={setNoDistributorSelectedModalIsOpen}
                onSetDistributor={selectDistributorHandler}
            />

            {userType.includes(Role.ROLE_ADMIN) || distributorsEmployedAtResponse?._total_items > 1 ? (
                <div ref={pickerRef}>
                    <div className="relative z-40 max-h-full">
                        <div
                            className={`cursor-pointer $"" ${open ? 'flex' : 'flex'}`}
                            ref={DistributorDropdownRef}
                            onClick={() => {
                                setOpen(!open);
                            }}>
                            <div className="w-full flex flex-row my-auto space-x-8 items-center">
                                <DistributorProfileImageRound
                                    size="24"
                                    distributor={currentDistributor}
                                />
                                <h1 className="w-full text-body-bold-16 text-white font-montserrat-bold whitespace-nowrap overflow-ellipsis overflow-hidden">
                                    {currentDistributor
                                        ? currentDistributor?.name
                                        : t('distributor-picker.choose-distributor')}
                                </h1>
                                <IconDown16
                                    className={`flex-none ${
                                        open ? '-rotate-180' : ''
                                    } transition duration-200 transform`}
                                />
                            </div>
                        </div>

                        <DistributorPickerDropdown
                            open={open}
                            setOpen={setOpen}
                            currentDistributor={currentDistributor}
                            onSearchDistributor={onSearchDistributor}
                            query={query}
                            page={page}
                            setPage={setPage}
                            distributorsEmployedAtResponse={distributorsEmployedAtResponse}
                            isLoadingDistributorsEmployedAt={isLoadingDistributorsEmployedAt}
                            isFetchingDistributorsEmployedAt={isFetchingDistributorsEmployedAt}
                            selectDistributorHandler={selectDistributorHandler}
                            onSignOutFromDistributorClick={onSignOutFromDistributorClick}
                        />
                    </div>
                </div>
            ) : (
                <div className="relative z-40 max-h-full">
                    <div className="w-full flex flex-row my-auto space-x-8 items-center">
                        <DistributorProfileImageRound
                            size="24"
                            distributor={distributorFromStore}
                        />
                        <h1 className="w-full text-body-bold-16 text-white font-montserrat-bold whitespace-nowrap overflow-ellipsis overflow-hidden">
                            {distributorFromStore
                                ? distributorFromStore?.name
                                : t('distributor-picker.choose-distributor')}
                        </h1>
                    </div>
                </div>
            )}
        </>
    );
}
