import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction } from 'react';

import DistributorPickerListItem from '@/components/pages/distributor/DistributorPickerListItem';
import ListLoadingSpinner from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ListLoadingSpinner';
import Button, { ButtonStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';
import DistributorProfileImageRound from '@cineamo/legacy-frontend-lib/src/components/ui/image/DistributorProfileImageRound';
import { BasicInputStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/input/BasicInput';
import SearchBar from '@cineamo/legacy-frontend-lib/src/components/ui/input/SearchBar';
import TmpPaginator from '@cineamo/legacy-frontend-lib/src/components/ui/table/TmpPaginator';

import { DistributorDto } from '@cineamo/legacy-frontend-lib/src/models/distributor/DistributorDto.types';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { DistributorListResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiListResponse.types';
import { ApiResponse } from '@cineamo/legacy-frontend-lib/src/api/ApiResponse.types';

import { constructDistributorAddress } from '@cineamo/legacy-frontend-lib/src/helper/distributor-helper';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconLocation24 } from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-location';

export type CinemaPickerDropdownProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    currentDistributor: DistributorDto;
    onSearchDistributor: (query: string) => void;
    query: string;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    distributorsEmployedAtResponse: ApiResponse<unknown, DistributorListResponse>;
    isLoadingDistributorsEmployedAt: boolean;
    isFetchingDistributorsEmployedAt: boolean;
    selectDistributorHandler: (distributor: DistributorDto) => void;
    onSignOutFromDistributorClick: () => void;
};

function DistributorPickerDropdown(props: CinemaPickerDropdownProps): JSX.Element {
    const {
        open,
        currentDistributor,
        onSearchDistributor,
        query,
        page,
        setPage,
        distributorsEmployedAtResponse,
        isLoadingDistributorsEmployedAt,
        isFetchingDistributorsEmployedAt,
        selectDistributorHandler,
        onSignOutFromDistributorClick
    } = props;

    const { t } = useTranslation('navigation');

    const { userType } = useUserStore();

    function constructAddress(currentDistributor: DistributorDto): JSX.Element {
        return (
            <div className="font-montserrat-bold flex flex-col whitespace-nowrap overflow-ellipsis overflow-hidden mx-14">
                {currentDistributor ? (
                    <>
                        <div className="whitespace-nowrap overflow-ellipsis overflow-hidden">
                            {currentDistributor?.name}
                        </div>
                        <div className="whitespace-nowrap overflow-ellipsis overflow-hidden text-body-bold-16">
                            {constructDistributorAddress(currentDistributor)}
                        </div>
                    </>
                ) : (
                    <span>{t('cinema-picker.choose-cinema')}</span>
                )}
            </div>
        );
    }

    function onSignOutFromDistributorClickInternal() {
        if (onSignOutFromDistributorClick) {
            onSignOutFromDistributorClick();
        }
    }

    return (
        <div
            className={classnames(
                open ? 'max-h-screen' : 'max-h-0',
                'fixed left-0 w-full px-12',
                'md:absolute md:left-auto md:right-0 md:w-auto md:min-w-300 md:px-0',
                'transform transition-all duration-200 overflow-hidden'
            )}>
            <div className="flex-col flex-grow w-full rounded-4 top-34 bg-white text-darkBlue shadow-2xl divide-gray divide-opacity-50 py-14 space-y-3">
                <div className="w-full flex flex-row my-auto px-14 pb-14 items-center">
                    <DistributorProfileImageRound distributor={currentDistributor} />
                    {constructAddress(currentDistributor)}
                    {currentDistributor && <IconLocation24 className="flex-none ml-auto" />}
                </div>

                {userType.includes(Role.ROLE_ADMIN) && currentDistributor && (
                    <Button
                        style={ButtonStyle.text_only}
                        onClick={onSignOutFromDistributorClickInternal}>
                        {t('distributor-picker.log-out-from-distributor')}
                    </Button>
                )}

                <div className="h-1 w-full bg-gray" />

                <SearchBar
                    className="mx-8"
                    basicInputProps={{
                        style: BasicInputStyle.gray
                    }}
                    value={query}
                    placeholder={t('distributor-picker.search-for-distributor')}
                    onChange={onSearchDistributor}
                />

                <div className="flex flex-col h-full overflow-y-auto">
                    {!(isLoadingDistributorsEmployedAt || isFetchingDistributorsEmployedAt) &&
                        distributorsEmployedAtResponse?._embedded?.distributors?.map((distributor) => (
                            <DistributorPickerListItem
                                key={distributor?.id}
                                className="w-full"
                                distributor={distributor}
                                onClick={selectDistributorHandler}
                            />
                        ))}

                    {!(isLoadingDistributorsEmployedAt || isFetchingDistributorsEmployedAt) &&
                        (!distributorsEmployedAtResponse || distributorsEmployedAtResponse._total_items <= 0) && (
                            <span className="px-14">{t('distributor-picker.no-distributor-in-list')}</span>
                        )}

                    {(isLoadingDistributorsEmployedAt || isFetchingDistributorsEmployedAt) && (
                        <ListLoadingSpinner
                            className="p-24"
                            dotClass="bg-darkBlue"
                        />
                    )}
                </div>

                {distributorsEmployedAtResponse?._page_count > 1 && (
                    <TmpPaginator
                        page={page}
                        setPage={setPage}
                        totalItems={distributorsEmployedAtResponse?._total_items}
                        pageCount={distributorsEmployedAtResponse?._page_count}
                    />
                )}
            </div>
        </div>
    );
}

export default DistributorPickerDropdown;
