import React, { ReactNode } from 'react';
import { useQuery } from 'react-query';

import SidebarNavigation from '@/components/navigation/SidebarNavigation';
import SideNavLayout from '@/components/SideNavLayout';

import { getMessages } from '@cineamo/legacy-frontend-lib/src/api/message/message-api';

import { apiResponseHandler } from '@cineamo/legacy-frontend-lib/src/helper/api-response-helper/ApiResponseHelper';

import { useNotificationsStore } from '@cineamo/legacy-frontend-lib/src/store/notifications/notificationsStore';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

export type BasicLayoutProps = {
    children?: ReactNode;
    pageProps: Record<string, unknown>;
};

function BasicLayout(props: BasicLayoutProps): JSX.Element {
    const { children } = props;

    const { setNotifications } = useNotificationsStore();
    const { user } = useUserStore();

    useQuery(['messages', user], async () => {
        const response = await getMessages({ userId: user.id });
        apiResponseHandler(response, (successResponse) => setNotifications(successResponse._embedded.messages));
    });

    return (
        <div className="h-[100dvh] flex flex-col font-montserrat-regular tracking-regular">
            <SideNavLayout
                nav={<SidebarNavigation />}
                pageProps={props.pageProps}
                content={children}
            />
        </div>
    );
}

export default BasicLayout;
