import { useTranslation } from 'next-i18next';
import React from 'react';

import SidebarNavigationItem from '@/components/navigation/SidebarNavigationItem';

import { NavigationDataGroup } from '@cineamo/legacy-frontend-lib/src/types/NavigationDataItem.type';

type NavigationItemGroupProps = {
    navigation: NavigationDataGroup;
};

function NavigationItemGroup(props: NavigationItemGroupProps) {
    const { navigation } = props;

    const { t } = useTranslation('navigation');

    return (
        <div>
            {navigation.label && (
                <label className="block pl-20 pr-16 mt-28 text-gray uppercase tracking-wider font-montserrat-semibold text-overline-10">
                    {t(navigation.label)}
                </label>
            )}
            {navigation.children?.map((item, index: number) =>
                React.isValidElement(item) ? (
                    <div
                        key={index}
                        className="pl-20 pr-16">
                        {item}
                    </div>
                ) : (
                    <SidebarNavigationItem
                        key={index}
                        link={item.href}
                        icon={item.icon}
                        text={t(item.title)}
                        neededPermission={item.necessaryPermission}
                    />
                )
            )}
        </div>
    );
}

export default NavigationItemGroup;
