import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import DistributorListItem from '@/components/pages/distributor/DistributorListItem';
import ListLoadingSpinner from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ListLoadingSpinner';
import { BasicInputStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/input/BasicInput';
import SearchBar from '@cineamo/legacy-frontend-lib/src/components/ui/input/SearchBar';
import Modal, { ModalProps } from '@cineamo/legacy-frontend-lib/src/components/ui/modal/Modal';
import TmpPaginator from '@cineamo/legacy-frontend-lib/src/components/ui/table/TmpPaginator';

import { DistributorDto } from '@cineamo/legacy-frontend-lib/src/models/distributor/DistributorDto.types';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { getDistributors } from '@cineamo/legacy-frontend-lib/src/api/distributor/distributor-api';

import { StyleFadeDirection, styleFaded } from '@cineamo/legacy-frontend-lib/src/helper/styleHelper';

import { useDistributorStore } from '@cineamo/legacy-frontend-lib/src/store/distributor/distributorStore';
import { useLoadingAnimation } from '@cineamo/legacy-frontend-lib/src/store/loading-animation-context';
import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

export type NoDistributorSelectedModalContentProps = {
    onSetDistributor: (distributor?: DistributorDto) => void;
};

function NoDistributorSelectedModalContent(props: NoDistributorSelectedModalContentProps): JSX.Element {
    const { onSetDistributor } = props;

    const { t } = useTranslation('navigation');

    const { showLoadingAnimation, hideLoadingAnimation } = useLoadingAnimation();

    const { user, userType } = useUserStore();

    const [query, setQuery] = useState<string>();
    const [page, setPage] = useState<number>(1);

    const router = useRouter();

    const { data: distributorsResponse, isLoading: isLoadingDistributors } = useQuery(
        ['distributor-select-modal', page, query],
        async () => {
            if (userType.includes(Role.ROLE_ADMIN)) {
                return getDistributors({ page, query });
            }
            return getDistributors({
                employedUserId: user.id,
                page,
                query
            });
        },
        { keepPreviousData: true, staleTime: 5000 }
    );

    function onSearchDistributor(value: string) {
        setPage(1);
        setQuery(value);
    }

    return (
        <div className="flex-auto flex flex-col h-full overflow-y-hidden my-12">
            <SearchBar
                className="mx-8 mb-12"
                basicInputProps={{
                    style: BasicInputStyle.gray
                }}
                value={query}
                placeholder={t('navigation:distributor-picker.search-for-distributor')}
                onChange={onSearchDistributor}
            />

            <div
                className="cinemas-employed-at-list-container flex flex-col space-y-24 h-auto flex-auto overflow-y-auto"
                style={styleFaded(StyleFadeDirection.Vertical, 10)}>
                {distributorsResponse?._embedded?.distributors.map((distributor, index) => (
                    <DistributorListItem
                        key={`distributorEmployedAt#${index}`}
                        distributor={distributor}
                        onSetDistributor={onSetDistributor}
                        router={router}
                        showLoadingAnimation={showLoadingAnimation}
                        hideLoadingAnimation={hideLoadingAnimation}
                    />
                ))}

                {!isLoadingDistributors && (!distributorsResponse || distributorsResponse._total_items <= 0) && (
                    <span className="px-14">{t('navigation:distributor-picker.no-distributor-in-list')}</span>
                )}

                {isLoadingDistributors && <ListLoadingSpinner dotClass="bg-darkBlue" />}
            </div>
            <TmpPaginator
                page={page}
                setPage={setPage}
                totalItems={distributorsResponse?._total_items}
                pageCount={distributorsResponse?._page_count}
            />
        </div>
    );
}

export type NoDistributorSelectedModalProps = {
    distributorList?: DistributorDto[];
    onSetDistributor?: (distributor?: DistributorDto) => void;
};

export default function NoDistributorSelectedModal(props: ModalProps & NoDistributorSelectedModalProps): JSX.Element {
    const { t } = useTranslation(['navigation', 'common']);

    const { onSetDistributor } = props;

    const { distributor } = useDistributorStore();

    function onSetDistributorInternal(distributor: DistributorDto) {
        if (onSetDistributor) {
            onSetDistributor(distributor);
        }
    }

    return (
        <Modal
            title={t('navigation:distributor-picker.no-distributor-selected-description')}
            closeable={!!distributor}
            onClose={null}
            {...props}
            t={t}>
            <div className="relative flex flex-col h-full overflow-y-hidden">
                <NoDistributorSelectedModalContent onSetDistributor={onSetDistributorInternal} />
            </div>
        </Modal>
    );
}
