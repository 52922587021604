import {CustomSVGProps} from '../../types/CustomSVGProps.types';

export function IconExplore48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46ZM48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8ZM6 24C6 14.0589 14.0589 6 24 6C33.9411 6 42 14.0589 42 24C42 33.9411 33.9411 42 24 42C14.0589 42 6 33.9411 6 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M26.2452 24.9908L29.5947 14.8469C29.6342 14.7272 29.601 14.5955 29.5094 14.5089C29.379 14.3855 29.1733 14.3912 29.05 14.5216L21.6134 22.3831L21.6101 22.3866L21.6101 22.3866L18.3055 32.5403C18.2665 32.6601 18.3002 32.7916 18.392 32.8778C18.5228 33.0007 18.7285 32.9942 18.8514 32.8634L26.2452 24.9908Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 6C24.5523 6 25 6.44771 25 7L25 12C25 12.5523 24.5523 13 24 13C23.4477 13 23 12.5523 23 12L23 7C23 6.44771 23.4477 6 24 6Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 35C24.5523 35 25 35.4477 25 36V41C25 41.5523 24.5523 42 24 42C23.4477 42 23 41.5523 23 41V36C23 35.4477 23.4477 35 24 35Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M41 24C41 24.5523 40.5523 25 40 25H36C35.4477 25 35 24.5523 35 24C35 23.4477 35.4477 23 36 23H40C40.5523 23 41 23.4477 41 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 24C13 24.5523 12.5523 25 12 25H8C7.44772 25 7 24.5523 7 24C7 23.4477 7.44772 23 8 23H12C12.5523 23 13 23.4477 13 24Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconExplore24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23 12C23 12.5523 22.5523 13 22 13L19 13C18.4477 13 18 12.5522 18 11.9999C18 11.4477 18.4477 11 19 11L22 11C22.5523 11 23 11.4477 23 12Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.99999 12C5.99998 12.5523 5.55227 13 4.99998 13L1.99998 13C1.4477 13 0.999985 12.5522 0.99999 12C0.999996 11.4477 1.44772 11 2 11L5 11C5.55228 11 6 11.4477 5.99999 12Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0.999995C12.5523 0.999992 13 1.4477 13 1.99999L13 4.99999C13 5.55227 12.5523 5.99999 12 5.99999C11.4477 6 11 5.55228 11 5L11 2C11 1.44772 11.4477 0.999998 12 0.999995Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 18C12.5523 18 13 18.4477 13 19L13 22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22L11 19C11 18.4477 11.4477 18 12 18Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M10.268 11L14.6098 6.50962C14.9716 6.13542 15.5931 6.49425 15.45 6.99469L13.7321 13L9.39024 17.4904C9.02842 17.8646 8.40691 17.5058 8.55007 17.0054L10.268 11Z"
                fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
