import { ParsedUrlQueryInput } from 'querystring';

import { NextRouter } from 'next/router';

import { DistributorDto } from '../models/distributor/DistributorDto.types';

import { formatDate_yyyyMMdd } from './date-helper';

type PrePostFunction = () => void;

const changeCinemaRedirect = (
    router: NextRouter,
    distributor: DistributorDto,
    preFunction?: PrePostFunction,
    postFunction?: PrePostFunction
): void => {
    if (preFunction) {
        preFunction();
    }

    const query: ParsedUrlQueryInput = {
        ...router.query,
        distributorId: distributor?.id
    };

    // check for catch all [[date]]
    if (Array.isArray(query.date) && query.date.length && query.date[0] === '[[...date]]') {
        const searchParams = new URLSearchParams(location.search);
        const dateString = searchParams.get('date') || new Date().toISOString();
        const date = formatDate_yyyyMMdd(dateString);

        query.date = [date];
    }

    router
        .replace(
            {
                pathname: router.pathname,
                query
            },
            undefined,
            {
                shallow: true
            }
        )
        .then(() => {
            if (postFunction) {
                postFunction();
            }
        })
        .catch(() => {
            if (postFunction) {
                postFunction();
            }
            router.push('/');
        });
};

export default changeCinemaRedirect;
