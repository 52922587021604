import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { NextRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Button, { ButtonStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';

import { DistributorDto } from '@cineamo/legacy-frontend-lib/src/models/distributor/DistributorDto.types';

import { DOMAIN_URL } from '@cineamo/legacy-frontend-lib/src/constants/http-constants';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';
import changeDistributorRedirect from '@cineamo/legacy-frontend-lib/src/helper/changeDistributorRedirect';

const defaultCinemaImage = loadAsset('/images/placeholders/im-cinemaDummy.png');

export type DistributorListItemProps = {
    distributor: DistributorDto;
    onSetDistributor: (distributor?: DistributorDto) => void;
    router: NextRouter;
    showLoadingAnimation: () => void;
    hideLoadingAnimation: () => void;
};

function DistributorListItem(props: DistributorListItemProps): JSX.Element {
    const { distributor, onSetDistributor, router, showLoadingAnimation, hideLoadingAnimation } = props;

    const { t } = useTranslation('navigation');

    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
    }, [distributor]);

    return (
        <li
            className="favorite-cinema-list-item px-16 py-8 rounded-4 hover:bg-darkBlue group transition-colors duration-100 hover:bg-opacity-20"
            key={`distributor#${distributor.id}`}>
            <div className="w-full flex flex-row items-center">
                <div className="relative w-48 h-48 flex-none">
                    <Image
                        layout="fill"
                        className="rounded-full object-cover"
                        objectFit="cover"
                        src={!error && distributor ? `${DOMAIN_URL}/${distributor?.imageUrl}` : defaultCinemaImage}
                        onError={() => setError(true)}
                        onErrorCapture={() => setError(true)}
                        alt="user image"
                    />
                </div>
                <span className="font-montserrat-bold mx-16">{`${distributor.name}`}</span>
                <Button
                    className="font-montserrat-semibold ml-auto opacity-100 md:opacity-0 transition-opacity duration-100
                                            group-hover:opacity-100 cursor-pointer text-darkBlue outline-none"
                    style={ButtonStyle.frameless}
                    onClick={() => {
                        if (onSetDistributor) {
                            onSetDistributor(distributor);
                        }

                        changeDistributorRedirect(router, distributor, showLoadingAnimation, hideLoadingAnimation);
                    }}>
                    {t('distributor-picker.select-distributor')}
                </Button>
            </div>
        </li>
    );
}

export default DistributorListItem;
