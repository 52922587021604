import React from 'react';

import NavigationSwitch from '@/components/navigation/NavigationSwitch';
import SideNavigationProfile from '@/components/navigation/SideNavigationProfile';

import { ImageCineamo48 } from '@cineamo/legacy-frontend-lib/src/logos/im-cineamo';

function SidebarNavigation(): JSX.Element {
    return (
        <div className="flex h-full text-white">
            <div className="w-full h-full overflow-y-auto bg-darkBlue bg-opacity-50 my-auto">
                <div className="flex justify-center py-16">
                    <ImageCineamo48 className="h-72 w-72" />
                </div>

                <nav className="my-auto">
                    <SideNavigationProfile />

                    <NavigationSwitch />
                </nav>
            </div>
        </div>
    );
}

export default SidebarNavigation;
