import { useTranslation } from 'next-i18next';
import React from 'react';

import { distributorNavigation } from '@/components/navigation/distributorNavigation';
import NavigationItemGroup from '@/components/navigation/NavigationItemGroup';
import SidebarNavigationItem from '@/components/navigation/SidebarNavigationItem';

import { NavigationDataGroup } from '@cineamo/legacy-frontend-lib/src/types/NavigationDataItem.type';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { IconDashboard24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-dashboard';

function NavigationSwitch(): JSX.Element {
    const { t } = useTranslation('navigation');

    let navigation: NavigationDataGroup[];

    const { userType } = useUserStore();

    if (userType.includes(Role.ROLE_ADMIN) || userType.includes(Role.ROLE_DISTRIBUTOR)) {
        navigation = distributorNavigation();
    } else {
        navigation = null;
    }

    if (!navigation) return <div />;

    return (
        <>
            <SidebarNavigationItem
                link="/"
                icon={<IconDashboard24 />}
                text={t('navigation.dashboard')}
            />
            {navigation.map((group, i) => (
                <NavigationItemGroup
                    key={i}
                    navigation={group}
                />
            ))}
        </>
    );
}

export default NavigationSwitch;
