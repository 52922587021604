import classnames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { DistributorDto } from '@cineamo/legacy-frontend-lib/src/models/distributor/DistributorDto.types';

import { DOMAIN_URL } from '@cineamo/legacy-frontend-lib/src/constants/http-constants';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';
import changeDistributorRedirect from '@cineamo/legacy-frontend-lib/src/helper/changeDistributorRedirect';

import { useLoadingAnimation } from '@cineamo/legacy-frontend-lib/src/store/loading-animation-context';

import { IconLocation24 } from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-location';

const defaultCinemaImage = loadAsset('/images/placeholders/im-cinemaDummy.png');

type DistributorPickerListItemProps = {
    className?: string;
    distributor: DistributorDto;
    onClick?: (distributor: DistributorDto) => void;
};

function DistributorPickerListItem(props: DistributorPickerListItemProps): JSX.Element {
    const { className, distributor } = props;

    const router = useRouter();

    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
    }, [distributor]);

    const { showLoadingAnimation, hideLoadingAnimation } = useLoadingAnimation();

    function changeSelectedDistributor(newDistributor: DistributorDto) {
        if (props.onClick) {
            props.onClick(newDistributor);
        }

        changeDistributorRedirect(router, newDistributor, showLoadingAnimation, hideLoadingAnimation);
    }

    return (
        <div
            className={classnames(
                className,
                'w-full px-16 py-8 flex flex-row cursor-pointer items-center transition-all duration-200',
                'hover:bg-purple hover:bg-opacity-20 active:opacity-80',
                'select-none group'
            )}
            onClick={() => changeSelectedDistributor(distributor)}
            title={distributor.name}>
            <div className="flex flex-row items-center space-x-8 mr-8">
                <div className={classnames('rounded-full relative flex-none overflow-hidden w-24 h-24')}>
                    <Image
                        src={!error && distributor ? `${DOMAIN_URL}/${distributor?.imageUrl}` : defaultCinemaImage}
                        layout="fill"
                        onError={() => setError(true)}
                        onErrorCapture={() => setError(true)}
                        objectFit="cover"
                        alt={`${distributor.name} distributor image`}
                    />
                </div>
                <span className="whitespace-nowrap overflow-ellipsis overflow-hidden">{distributor.name}</span>
            </div>
            <IconLocation24 className="flex-none ml-auto opacity-0 group-hover:opacity-100 transition-all duration-200" />
        </div>
    );
}

export default DistributorPickerListItem;
