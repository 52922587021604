import classnames from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import { loadAsset } from '../../../helper/asset-helper';
import { IconLocation24 } from '../../../icons/menu/ic-location';
import { DistributorDto } from '../../../models/distributor/DistributorDto.types';

const defaultCinemaImage = loadAsset('/images/placeholders/im-cinemaDummy.png');

export type DistributorProfileImageProps = {
    className?: string;
    size?: '24';
    distributor: DistributorDto;
};

export default function DistributorProfileImageRound(props: DistributorProfileImageProps): JSX.Element {
    const { className, distributor } = props;

    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
    }, [distributor]);

    if (!distributor) {
        return <IconLocation24 className="flex-none" />;
    }

    let size: string;
    switch (props.size) {
        case '24':
            size = 'w-24 h-24';
            break;
        default:
            size = 'w-32 h-32';
            break;
    }

    return (
        <div className={classnames(className, 'relative flex-none bg-cover', size)}>
            <Image
                className={classnames(distributor?.imageUrl && 'rounded-4')}
                src={!error && distributor?.imageUrl ? distributor?.imageUrl : defaultCinemaImage}
                width="100%"
                height="100%"
                objectFit={distributor?.imageUrl ? 'contain' : 'cover'}
                onError={() => setError(true)}
                alt={`${distributor.name} cinema image`}
            />
        </div>
    );
}
