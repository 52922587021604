import { signOut } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';

import MakeAppointmentCard from '@/components/ui/cards/MakeAppointmentCard';
import Button, { ButtonStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';
import WebsiteHeader from '@cineamo/legacy-frontend-lib/src/components/website/WebsiteHeader';
import WebsitePage from '@cineamo/legacy-frontend-lib/src/components/website/WebsitePage';

import { DOMAIN_URL, SIGNOUT_URL } from '@cineamo/legacy-frontend-lib/src/constants/http-constants';
import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import { loadAsset } from '@cineamo/legacy-frontend-lib/src/helper/asset-helper';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

const imageNoAccess = loadAsset('/images/illustrations/im-noAccess.png');

export type SessionProtectionProps = {
    children?: ReactNode;
    locale?: string;
};

function AuthorizationProtection(props: SessionProtectionProps): JSX.Element {
    const { children, locale } = props;

    const { t } = useTranslation('common');

    const { userType, logout } = useUserStore();

    const hasAccessToPage = userType.includes(Role.ROLE_DISTRIBUTOR) || userType.includes(Role.ROLE_ADMIN);

    function logoutUser() {
        signOut({ callbackUrl: SIGNOUT_URL(locale) }).then(() => {
            logout();
        });
    }

    return (
        <>
            {hasAccessToPage && children}
            {!hasAccessToPage && (
                <main className="flex flex-col px-16 md:px-24 lg:px-48 h-[100dvh] justify-center">
                    <WebsitePage
                        headerClassName="items-center h-full"
                        header={
                            <WebsiteHeader
                                tag={t('access.authorization.tag')}
                                title={t('access.authorization.title')}
                                subtitle={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('access.authorization.subtitle')
                                        }}
                                    />
                                }
                                customElement={
                                    <div className="flex flex-col lg:flex-row lg:space-x-16 space-y-16 lg:space-y-0">
                                        <a
                                            className="flex flex-col"
                                            href={`${DOMAIN_URL}/contact`}
                                            target="_blank"
                                            rel="noreferrer">
                                            <Button style={ButtonStyle.outlined}>{t('common:contact-support')}</Button>
                                        </a>
                                        <Button onClick={logoutUser}>{t('common:switch-user')}</Button>
                                    </div>
                                }
                                image={imageNoAccess}
                                imageAlt={t('no-access.image')}
                                customElement2={<MakeAppointmentCard t={t} />}
                            />
                        }
                    />
                </main>
            )}
        </>
    );
}

export default AuthorizationProtection;
