import { DistributorMovieStatisticsDto } from '@cineamo/api/models/distributor/DistributorMovieStatisticsDto.types';

import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';
import { DistributorDto, DistributorInputDto } from '../../models/distributor/DistributorDto.types';
import { DistributorMovieListDto } from '../../models/distributor/DistributorMovieListDto.types';
import {
    DistributorMovieListItemDto,
    DistributorMovieListItemInputDto
} from '../../models/distributor/DistributorMovieListItemDto.types';
import { ApiErrorResponse } from '../ApiErrorResponse.types';
import {
    DistributorListResponse,
    DistributorMovieListItemsResponse,
    DistributorMovieListsResponse
} from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Distributors ------------------------>

type GetDistributorsQueryParameter = {
    employedUserId?: string | number;
} & UrlQueryParameter;

/**
 * This endpoint returns a list of distributors.
 *
 * @example
 * // Get a list of distributors:
 * getDistributors({...params});
 *
 * @param params
 * @returns {ApiResponse<DistributorListResponse>}
 */

export async function getDistributors(
    params?: GetDistributorsQueryParameter
): Promise<ApiResponse<unknown, DistributorListResponse>> {
    return apiClient
        .get(`/distributors${buildUrlQuery(params)}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Create Distributor ------------------------>

/**
 * This endpoint returns a distributor.
 *
 * @example
 * // Create a distributor:
 * createDistributor({...distributor});
 *
 * @param distributor
 * @returns {ApiResponse<DistributorDto> | ApiErrorResponse<DistributorDto>}
 */

export async function createDistributor(
    distributor: DistributorInputDto
): Promise<ApiResponse<DistributorDto> | ApiErrorResponse<DistributorInputDto>> {
    return apiClient
        .post('/distributors', distributor)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Delete Distributor ------------------------>

/**
 * This endpoint returns a boolean.
 *
 * @example
 * // Delete a distributor by id:
 * deleteDistributorById(1);
 *
 * @param distributorId
 * @returns {boolean}
 */

export async function deleteDistributorById(distributorId: string | number): Promise<boolean> {
    return apiClient
        .delete(`/distributors/${distributorId}`)
        .then((response) => response.status === 204)
        .catch(() => {
            return false;
        });
}

// <------------------------ Distributor ------------------------>

/**
 * This endpoint returns a distributor.
 *
 * @example
 * // Get a distributor by id:
 * getDistributorById(1);
 *
 * @param distributorId
 * @returns {ApiResponse<DistributorDto>}
 */

export async function getDistributorById(distributorId: number | string): Promise<ApiResponse<DistributorDto>> {
    return apiClient
        .get(`/distributors/${distributorId}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Distributor Movies List ------------------------>

/**
 * This endpoint returns a list of distributor movies.
 *
 * @example
 * // Get a distributor by id:
 * createDistributorMovieList(1, 'Studiocanal');
 *
 * @param distributorId
 * @param name
 * @returns {ApiResponse<DistributorMovieListDto> | ApiErrorResponse<DistributorMovieListDto>}
 */

export async function createDistributorMovieList(
    distributorId: number,
    name: string
): Promise<ApiResponse<DistributorMovieListDto> | ApiErrorResponse<DistributorMovieListDto>> {
    return apiClient
        .post('/distributor-movie-lists', {
            name: name,
            distributor: distributorId,
            isActive: true
        })
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Distributor Movie List ------------------------>

type GetDistributorMovieListsQuery = {
    distributorId?: number | string;
} & UrlQueryParameter;

/**
 * This endpoint returns a list of distributor movie lists.
 *
 * @example
 * // Get a list of distributor movie lists:
 * getDistributorMovieLists({...query});
 *
 * @param query
 * @returns {ApiResponse<DistributorMovieListsResponse>}
 */

export async function getDistributorMovieLists(
    query?: GetDistributorMovieListsQuery
): Promise<ApiResponse<DistributorMovieListsResponse>> {
    return apiClient
        .get(`/distributor-movie-lists${buildUrlQuery(query)}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Delete Distributor Movie List ------------------------>

/**
 * This endpoint returns a boolean.
 *
 * @example
 * // Delete a distributor movie list by id:
 * deleteDistributorMovieListById(1);
 *
 * @param distributorMovieListId
 * @returns {boolean}
 */

export async function deleteDistributorMovieListById(distributorMovieListId: number): Promise<boolean> {
    return apiClient
        .delete(`/distributor-movie-lists/${distributorMovieListId}`)
        .then((response) => response.status === 204)
        .catch(() => {
            return false;
        });
}

// <------------------------ Create Distributor Movie List ------------------------>

/**
 * This endpoint returns a distributor movie list.
 *
 * @example
 * // Create a distributor movie list:
 * createDistributorMovieListItem({...data}, 1);
 *
 * @param data
 * @param distributorMovieListId
 * @returns {ApiResponse<DistributorMovieListItemDto> | ApiErrorResponse<DistributorMovieListItemDto>}
 */

export async function createDistributorMovieListItem(
    data: DistributorMovieListItemInputDto,
    distributorMovieListId: number
): Promise<ApiResponse<DistributorMovieListItemDto> | ApiErrorResponse<DistributorMovieListItemInputDto>> {
    return apiClient
        .post('/distributor-movie-list-items', {
            ...data,
            distributorMovieList: distributorMovieListId
        })
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Delete Distributor Movie List Item ------------------------>

/**
 * This endpoint returns a boolean.
 *
 * @example
 * // Delete a distributor movie list item by id:
 * deleteDistributorMovieListItemById(1);
 *
 * @param distributorMovieListItemId
 * @returns {boolean}
 */

export async function deleteDistributorMovieListItemById(distributorMovieListItemId: number): Promise<boolean> {
    return apiClient
        .delete<boolean>(`/distributor-movie-list-items/${distributorMovieListItemId}`)
        .then((response) => response.status === 204)
        .catch(() => {
            return false;
        });
}

// <------------------------ Distributor Movie List Items ------------------------>

type GetDistributorMovieListItemsQuery = {
    distributorMovieListId?: number | string;
} & UrlQueryParameter;

/**
 * This endpoint returns a list of distributor movie list items.
 *
 * @example
 * // Get a list of distributor movie list items:
 * getDistributorMovieListItems({...params});
 *
 * @param params
 * @returns {ApiResponse<DistributorMovieListItemsResponse>}
 */

export async function getDistributorMovieListItems(
    params?: GetDistributorMovieListItemsQuery
): Promise<ApiResponse<unknown, DistributorMovieListItemsResponse>> {
    const url = `/distributor-movie-list-items${buildUrlQuery(params)}`;
    return apiClient
        .get(url)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Update Distributor ------------------------>

/**
 * This endpoint returns a distributor.
 *
 * @example
 * // Update a distributor:
 * updateDistributor(1, {...distributor});
 *
 * @param distributor
 * @param distributorId
 * @returns {ApiResponse<DistributorDto>}
 */

export async function updateDistributor(
    distributorId: number | string,
    distributor: DistributorInputDto
): Promise<ApiResponse<DistributorDto> | ApiErrorResponse<DistributorInputDto>> {
    return apiClient
        .patch(`/distributors/${distributorId}`, JSON.stringify(distributor))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

/**
 * This endpoint generates and fetches MAD movie report
 * @param movieId
 */
export async function getDistributorMovieStatistics(
    movieId: string
): Promise<ApiResponse<DistributorMovieStatisticsDto>> {
    return apiClient
        .post('/get-distributor-movie-statistics', JSON.stringify({ movieId }))
        .then((response) => response.data)
        .catch(() => false);
}
