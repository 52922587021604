import QueryClientProviderWrapper from '@cineamo/legacy-frontend-lib/src/provider/QueryClientProvider';
// eslint-disable-next-line import/no-unresolved
import { Analytics } from '@vercel/analytics/react';
import { Session } from 'next-auth';
import { SSRConfig, appWithTranslation, useTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { ToastContainer } from 'react-toastify';

import AuthorizationProtection from '@/components/layouts/AuthorizationProtection';
import BasicLayout from '@/components/layouts/BasicLayout';
import SessionProtection from '@/components/layouts/SessionProtection';
import SessionProviderWrapper from '@/components/layouts/SessionProviderWrapper';

import {
    DistributorStoreProvider,
    useCreateDistributorStore
} from '@cineamo/legacy-frontend-lib/src/store/distributor/distributorStore';
import {
    NotificationsStoreProvider,
    useCreateNotificationsStore
} from '@cineamo/legacy-frontend-lib/src/store/notifications/notificationsStore';
import { UserStoreProvider, useCreateUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import '../styles/globals.scss';

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
    const [queryClient] = useState(() => new QueryClient());

    const { t } = useTranslation('common');

    const createUserStore = useCreateUserStore(pageProps.initialUserStoreState);

    const createDistributorStore = useCreateDistributorStore(pageProps.initialDistributorStoreState);

    const createNotificationsStore = useCreateNotificationsStore({
        ...pageProps.initialNotificationsStoreState
    });

    const combinedPageProps = {
        ...pageProps
    };

    return (
        <>
            <Head>
                <title>{t('app-name')}</title>
            </Head>
            <SessionProviderWrapper session={session}>
                <QueryClientProviderWrapper pageProps={pageProps}>
                    <QueryClientProvider
                        client={queryClient}
                        contextSharing={true}>
                        <Hydrate state={pageProps.dehydratedState}>
                            <UserStoreProvider createStore={createUserStore}>
                                <DistributorStoreProvider createStore={createDistributorStore}>
                                    <ToastContainer />
                                    <SessionProtection>
                                        <AuthorizationProtection>
                                            <NotificationsStoreProvider createStore={createNotificationsStore}>
                                                <BasicLayout pageProps={pageProps}>
                                                    <Component {...combinedPageProps} />
                                                </BasicLayout>
                                            </NotificationsStoreProvider>
                                        </AuthorizationProtection>
                                    </SessionProtection>
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </DistributorStoreProvider>
                            </UserStoreProvider>
                        </Hydrate>
                    </QueryClientProvider>
                </QueryClientProviderWrapper>
            </SessionProviderWrapper>
            <Analytics />
        </>
    );
}

export default appWithTranslation<AppProps<SSRConfig & { session: Session }>>(MyApp);
